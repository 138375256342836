<template>
  <div v-if="currentItem" class="content-wrapper my-3 mx-1">
    <h2>Dettagli</h2>
    <div class="header d-flex justify-content-between mb-4">
      <div class="d-flex flex-grow-1" style="flex-direction: column">
        <div>
          <span style="font-weight: bold; font-size: 1.2rem">{{
            currentItem.luogoInizio
          }}</span>
          -
          <span
            >{{
              new Date(currentItem.dataOraInizio).toLocaleDateString("it-IT")
            }}
            -
            {{
              new Date(currentItem.dataOraInizio).toLocaleTimeString("it-IT", {
                hour: "2-digit",
                minute: "2-digit",
              })
            }}</span
          >
        </div>
        <div class="d-flex">
          <span
            v-if="currentItem.luogoFine"
            style="font-weight: bold; font-size: 1.2rem"
            >{{ currentItem.luogoFine }} -</span
          >
          <span v-if="currentItem.dataOraFine"
            >{{ new Date(currentItem.dataOraFine).toLocaleDateString("it-IT") }}
            -
            {{
              new Date(currentItem.dataOraFine).toLocaleTimeString("it-IT", {
                hour: "2-digit",
                minute: "2-digit",
              })
            }}</span
          >
        </div>
      </div>

      <div>
        <div v-if="currentItem.stato == '2'" @click="currentItem.stato = '1'">
          <span class="stato" style="color: green; font-weight: bold">
            <b-icon-circle-fill></b-icon-circle-fill> Pubblica
          </span>
        </div>
        <div v-if="currentItem.stato == '1'" @click="currentItem.stato = '0'">
          <span class="stato" style="color: orange; font-weight: bold">
            <b-icon-circle-fill></b-icon-circle-fill> Interna
          </span>
        </div>
        <div v-if="currentItem.stato == '0'" @click="currentItem.stato = '3'">
          <span class="stato" style="color: maroon; font-weight: bold">
            <b-icon-circle-fill></b-icon-circle-fill> Esclusa
          </span>
        </div>
        <div v-if="currentItem.stato == '3'" @click="currentItem.stato = '2'">
          <span class="stato" style="color: purple; font-weight: bold">
            <b-icon-circle-fill></b-icon-circle-fill> Da analizzare
          </span>
        </div>
      </div>
    </div>
    <div class="agente mt-4 mb-4">
      <b-avatar
        v-if="currentItem.CieFoto"
        :src="currentItem.CieFoto"
        class="mr-2"
      ></b-avatar>
      <b-avatar v-else class="mr-2"></b-avatar>
      <span style="font-weight: bold">{{ currentItem.CieRegistrato }}</span>
    </div>
    <div class="body mb-1">
      <b-form-group>
        <b-form-textarea
          v-model="nuovaDescrizione"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
      <div v-if="descrizioneHistoryVisible">
        <div
          class="my-4"
          style="color: gray"
          v-for="(log, i) in currentItem.descrizioneHistory"
          :key="i"
        >
          {{ log.testo }}

          <div style="font-size: 0.8rem; font-style: italic">
            <span style="font-weight: bold">{{ log.Utente }}:</span>
            {{ new Date(log.dataOraInserimento).toLocaleDateString("it") }} -
            {{
              new Date(log.dataOraInserimento).toLocaleTimeString("it", {
                hour: "2-digit",
                minute: "2-digit",
              })
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="footer d-flex justify-content-end mb-4">

      <div class="azioni ">
        <b-icon-calendar-fill v-if="!descrizioneHistoryVisible" @click="showDescrizioneHistory" role="button"
          class="mr-2"></b-icon-calendar-fill>
        <b-icon-calendar v-else @click="hideDescrizioneHistory" role="button" class="mr-2"></b-icon-calendar>

      </div>
    </div> -->
    <!-- <div class="w-100">
      <div v-if="imageSelected" class=" modal-image">
        <img class="w-100" :src="imageSelected.urlElemento" id="main">
      </div>
      <div class="images mt-4">
        <div @click="setCurrentImg(img)" v-b-modal.modal-img class="image"
          v-for="(img, index) of currentItem.elementiMultimediali" :key="index">
          <img @click="selectImage(img)" :class="setClassStato(img.stato)" :src="img.urlElemento">
        </div>
      </div>
    </div> -->

    <h2>Elementi multimediali</h2>

    <div class="w-100">
      Tutti:
      <b-badge
        @click="settAll('Esclusa')"
        variant="danger"
        class="cursor-pointer mr-2"
        >Esclusi</b-badge
      >
      <b-badge
        @click="settAll('Pubblica')"
        variant="success"
        class="cursor-pointer mr-2"
        >Pubblicati</b-badge
      >
      <b-badge @click="settAll('Interna')" class="cursor-pointer mr-2"
        >Interni</b-badge
      >
    </div>

    <div class="w-100">
      <!-- directive -->
      <div class="images" v-viewer>
        <div
          class="image"
          v-for="item in currentItem.elementiMultimediali"
          :key="item.id"
        >
          <div
            v-if="item.stato == '1' || item.stato == '2' || item.stato == '3'"
          >
            <div>
              <div v-if="item.stato == '1'" @click="item.stato = '2'">
                <span class="stato" style="color: orange; font-weight: bold">
                  <b-icon-circle-fill></b-icon-circle-fill> Interna
                </span>
              </div>
              <div v-if="item.stato == '2'" @click="item.stato = '0'">
                <span class="stato" style="color: green; font-weight: bold">
                  <b-icon-circle-fill></b-icon-circle-fill> Pubblica
                </span>
              </div>
              <div v-if="item.stato == '0'" @click="item.stato = '3'">
                <span class="stato" style="color: maroon; font-weight: bold">
                  <b-icon-circle-fill></b-icon-circle-fill> Esclusa
                </span>
              </div>
              <div v-if="item.stato == '3'" @click="item.stato = '1'">
                <span class="stato" style="color: purple; font-weight: bold">
                  <b-icon-circle-fill></b-icon-circle-fill> Da analizzare
                </span>
              </div>
            </div>
            <img :src="item.urlElemento" />
          </div>
        </div>
      </div>
    </div>

    <h2 class="cursor-pointer" @click="toggleItemEsclusiVisible">
      Elementi Esclusi
      <b-icon-chevron-up v-if="itemEsclusiVisible"></b-icon-chevron-up
      ><b-icon-chevron-down v-else></b-icon-chevron-down>
    </h2>
    <div v-if="itemEsclusiVisible" class="w-100">
      <!-- directive -->
      <div class="images" v-viewer>
        <div
          class="image"
          v-for="item in currentItem.elementiMultimediali"
          :key="item.id"
        >
          <div v-if="item.stato == '0'">
            <div>
              <div v-if="item.stato == '1'" @click="item.stato = '2'">
                <span class="stato" style="color: orange; font-weight: bold">
                  <b-icon-circle-fill></b-icon-circle-fill> Interna
                </span>
              </div>
              <div v-if="item.stato == '2'" @click="item.stato = '0'">
                <span class="stato" style="color: green; font-weight: bold">
                  <b-icon-circle-fill></b-icon-circle-fill> Pubblica
                </span>
              </div>
              <div v-if="item.stato == '0'" @click="item.stato = '3'">
                <span class="stato" style="color: maroon; font-weight: bold">
                  <b-icon-circle-fill></b-icon-circle-fill> Esclusa
                </span>
              </div>
              <div v-if="item.stato == '3'" @click="item.stato = '1'">
                <span class="stato" style="color: purple; font-weight: bold">
                  <b-icon-circle-fill></b-icon-circle-fill> Da analizzare
                </span>
              </div>
            </div>
            <img :src="item.urlElemento" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2 mb-4">
      <b-badge role="button" variant="primary">Download Immagini</b-badge>
    </div>

    <div class="w-100 d-flex justify-content-end pt-4 pb-4 mt-4 mb-4">
      <b-button
        squared
        :disabled="loading"
        variant="primary"
        size="lg"
        class="big-button"
        @click="saveItem"
        >{{ loading ? "Loading..." : "Salva" }}</b-button
      >
    </div>
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService.js"
export default {
  name: "SingolaRilevazioneDettagliata",
  props: {
    item: Object,
  },
  mounted() {
    this.currentItem = { ...this.item }
  },
  data() {
    return {
      itemEsclusiVisible: false,
      advancedFilter_visible: false,
      show: false,
      currentImg: "",
      filter: "",
      idOrdine: null,
      currentItem: null,
      tmpItem: {
        id: null,
        title: "",
        luogo_inizio: "",
        confermato: false,
        agente: "",
        data_suggerita: "",
        ora_suggerita: "",
        sospettati: null,
        annotazioni: "",
      },
      loading: false,
      items: [],
      descrizioneHistoryVisible: false,
      nuovaDescrizione: this.item.descrizioneHistory[0].testo,
      imageSelected: null,
    }
  },
  methods: {
    toggleItemEsclusiVisible() {
      this.itemEsclusiVisible = !this.itemEsclusiVisible
    },
    settAll(status) {
      console.log(
        "🚀 ~ file: SingolaRilevazioneDettagliata.vue:173 ~ settAll ~ status",
        status
      )
      switch (status) {
        case "Esclusa":
          this.currentItem.elementiMultimediali.forEach((element) => {
            element.stato = "0"
          })
          break
        case "Pubblica":
          this.currentItem.elementiMultimediali.forEach((element) => {
            element.stato = "2"
          })
          break
        case "Interna":
          this.currentItem.elementiMultimediali.forEach((element) => {
            element.stato = "1"
          })
          break
      }
    },
    showImage() {
      this.$viewerApi({
        images: this.images,
      })
    },
    selectImage(img) {
      this.imageSelected = img
    },
    resetData() {
      this.tmpItem = {
        id: null,
        title: "",
        luogo_inizio: "",
        confermato: false,
        agente: "",
      }
    },
    saveItem() {
      this.loading = true
      this.salvaDescrizione()
      this.salvaStatoRilevazione(this.currentItem.stato)
      this.salvaStatoImmagini()
    },
    setCurrentImg(img) {
      this.currentImg = {}
      this.currentImg = img
    },

    setClassStato(id) {
      let imgClass = ""
      switch (id) {
        case 0:
          imgClass = "Interna"
          break
        case 1:
          imgClass = "Esclusa"
          break
        case 2:
          imgClass = "Pubblicazione"
          break
      }
      return imgClass
    },
    showDescrizioneHistory() {
      this.descrizioneHistoryVisible = true
    },
    hideDescrizioneHistory() {
      this.descrizioneHistoryVisible = false
    },
    salvaDescrizione() {
      const service = new AxiosService(
        "DossierManager/EditDescrizioneRilevazione/" + this.item.id
      )
      const nuovaDescrizioneObj = {
        descrizione: this.nuovaDescrizione,
        elementiMultimediali: [],
      }

      service
        .create(nuovaDescrizioneObj)
        .then((res) => console.log(res))
        .finally(() => {
          this.$emit("closeSidebar")
          this.loading = false
        })
    },
    salvaStatoRilevazione(idStato) {
      const service = new AxiosService()
      service
        .putCustomEndpoint(
          "DossierManager/EditStatoRilvezione/" + this.item.id + "/" + idStato
        )
        .then((res) => {
          console.log(res)
        })
    },
    salvaStatoImmagini() {
      const service = new AxiosService()
      this.currentItem.elementiMultimediali.forEach((elemento) => {
        service
          .putCustomEndpoint(
            "DossierManager/EditStatoMediaRilvezione/" +
              elemento.id +
              "/" +
              elemento.stato
          )
          .then((res) => {
            console.log(res)
          })
      })
    },
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0
    },

    // ...mapGetters(["languages", "default_lang", "config"]),
    //     ...mapGetters(["default_lang"]),
    //     ...mapGetters(["config"]),
  },
}
</script>

<style>
.emm {
  border: 2px dashed red;
}
</style>
